.SplitScreen {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	min-height: calc(100vh - 80px);
}

.SplitScreen > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.SplitScreen > div {
	max-width: 800px;
	width: 100%;
	justify-self: flex-end;
	padding: 48px;
}

@media (max-width: 1200px) {
	.SplitScreen {
		display: flex;
		flex-direction: column-reverse;
	}

	.SplitScreen > img {
		height: 300px;
	}
}