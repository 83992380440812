@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap);
:root {
  --color__gray: rgba(230,230,230,0.98);
  --color__gray_dark: rgb(0,123,99);
  --color__blue: rgb(224, 242, 254);
  --color__on_gray: #000;
  --color__on_blue: #000;

  --content_width: 1200px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 50px;
}


@media (max-width: 1200px) {
  h1 {
    font-size: 40px;
  }
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  line-height: 1.2;
}
/* 
.text-center {
  text-align: center;
} */

img {
  max-width: 100%
}
.SplitScreen {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	min-height: calc(100vh - 80px);
}

.SplitScreen > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.SplitScreen > div {
	max-width: 800px;
	width: 100%;
	justify-self: flex-end;
	padding: 48px;
}

@media (max-width: 1200px) {
	.SplitScreen {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
	}

	.SplitScreen > img {
		height: 300px;
	}
}
